<template>
  <div class="asideCreatorMenuOthers">
    <LargeButton
      :label="$locale['my_notifications']"
      :class="`${mkLink($links['my-notifications'], 'on')}`"
      @click="goToNav({ view: _view }, $links['my-notifications'])"
    />

    <LargeButton
      :label="$locale['my_subscriptions']"
      :class="`${mkLink('/?feed=my-subscriptions', 'on')}`"
      @click="goToNav({ feed: 'my-subscriptions', view: _view }, '/')"
    />

    <LargeButton
      :label="$locale['my_credits']"
      :class="`${mkLink($links['my-credits'], 'on')} ${mkLink($links['my-credits-history'], 'on')}`"
      @click="goToNav({ view: _view }, $links['my-credits'])"
    />

    <LargeButton
      :label="$locale['settings']"
      :class="`${mkLink($links['my-profile'], 'on')}`"
      @click="goToNav({ view: _view }, $links['my-profile'])"
    />
  </div>
</template>

<script>
export default {
  props: ["_view", "modal"],
};
</script>
